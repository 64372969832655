<template>
  <div>
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      v-if="this.$router.currentRoute.path!='/home'"
    >
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div
      shadow="hover"
      class="box-card"
      align="middle"
    >
      <el-row>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <!-- 系统设置 -->
            <div class="head">
              <i
                class="el-icon-s-tools"
                style="font-size: 18px;"
              ></i>
              <span class="text">系统设置</span>
            </div>
            <br/>
            <el-form
              :model="coalSystemSetting"
              label-width="150px"
              :rules="rules"
            >
              <el-form-item label="增加系统：">
                <el-switch v-model="coalSystemSetting.backups"></el-switch>
              </el-form-item>
              <el-form-item label="批量删除：">
                <el-switch v-model="coalSystemSetting.batchremove"></el-switch>
              </el-form-item>
              <el-form-item
                label="单页展示数："
                prop="numberperpage"
              >
                <el-input-number
                  v-model="coalSystemSetting.numberperpage"
                  :step="10"
                  :min="10"
                  :max="50"
                >
                </el-input-number>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple-light">
            <!-- 子系统设置 -->
            <div style="float:left">
              <i
                class="el-icon-s-tools"
                style="font-size: 16px;"
              ></i>
              <span  class="text">子系统设置</span>
            </div>
            <br/>
            <el-form
              :model="coalSubsystemSetting"
              label-width="150px"
              :rules="rules"
            >
              <el-form-item label="增加子系统：">
                <el-switch v-model="coalSubsystemSetting.backups"></el-switch>
              </el-form-item>
              <el-form-item label="批量删除：">
                <el-switch v-model="coalSubsystemSetting.batchremove"></el-switch>
              </el-form-item>
              <el-form-item
                label="单页展示数："
                prop="numberperpage"
              >
                <el-input-number
                  v-model="coalSubsystemSetting.numberperpage"
                  :step="10"
                  :min="10"
                  :max="50"
                >
                </el-input-number>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <!-- 设备设置 -->
            <div style="float:left">
              <i
                class="el-icon-s-tools"
                style="font-size: 16px;"
              ></i>
              <span  class="text">设备设置</span>
            </div>
            <br/>
            <el-form
              :model="coalEquipSetting"
              label-width="150px"
              :rules="rules"
            >
              <el-form-item label="增加设备：">
                <el-switch v-model="coalEquipSetting.backups"></el-switch>
              </el-form-item>
              <el-form-item label="批量删除：">
                <el-switch v-model="coalEquipSetting.batchremove"></el-switch>
              </el-form-item>
              <el-form-item
                label="单页展示数："
                prop="numberperpage"
              >
                <el-input-number
                  v-model="coalEquipSetting.numberperpage"
                  :step="10"
                  :min="10"
                  :max="50"
                >
                </el-input-number>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>

      <el-row style="margin-top:40px">
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <!-- 附属设备设置 -->
            <div style="float:left">
              <i
                class="el-icon-s-tools"
                style="font-size: 16px;"
              ></i>
              <span  class="text">附属设备设置</span>
            </div>
            <br>
            <el-form
              :model="coalSubequipSetting"
              label-width="150px"
              :rules="rules"
            >
              <el-form-item label="增加附属设备：">
                <el-switch v-model="coalSubequipSetting.backups"></el-switch>
              </el-form-item>
              <el-form-item label="批量删除：">
                <el-switch v-model="coalSubequipSetting.batchremove"></el-switch>
              </el-form-item>
              <el-form-item
                label="单页展示数："
                prop="numberperpage"
              >
                <el-input-number
                  v-model="coalSubequipSetting.numberperpage"
                  :step="10"
                  :min="10"
                  :max="50"
                >
                </el-input-number>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple-light">
            <!-- 数据项设置 -->
            <div style="float:left">
              <i
                class="el-icon-s-tools"
                style="font-size: 16px;"
              ></i>
              <span  class="text">数据项设置</span>
            </div>
            <br>
            <el-form
              :model="coalMeapointSetting"
              label-width="150px"
              :rules="rules"
            >
              <el-form-item label="增加数据项：">
                <el-switch v-model="coalMeapointSetting.backups"></el-switch>
              </el-form-item>
              <el-form-item label="批量删除：">
                <el-switch v-model="coalMeapointSetting.batchremove"></el-switch>
              </el-form-item>
              <el-form-item
                label="单页展示数："
                prop="numberperpage"
              >
                <el-input-number
                  v-model="coalMeapointSetting.numberperpage"
                  :step="10"
                  :min="10"
                  :max="50"
                >
                </el-input-number>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
      <el-button
        class="finish"
        type="primary"
        @click="onSubmit"
      >完成</el-button>

    </div>

  </div>
</template>

<script>
// 验证规则，需为数字值
const checkTime = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("输入不能为空"));
  }
  console.log("checkTime", value);
  let intValue = parseInt(value);
  if (!Number.isInteger(intValue)) {
    return callback(new Error("请输入数字值"));
  }
  callback();
};
export default {
  name: "set_coalSystemManage",
  data() {
    return {
      nameArr: ["项目系统", "项目子系统", "项目设备", "项目附属设备", "项目数据项"],
      settingNameArr: [
        "coalSystemSetting",
        "coalSubsystemSetting",
        "coalEquipSetting",
        "coalSubequipSetting",
        "coalMeapointSetting",
      ],
      coalSystemSetting: {
        name: "项目系统",
        numberperpage: 10,
        backups: true,
        batchremove: true,
      },
      coalSubsystemSetting: {
        name: "项目子系统",
        numberperpage: 10,
        backups: true,
        batchremove: true,
      },
      coalEquipSetting: {
        name: "项目设备",
        numberperpage: 10,
        backups: false,
        batchremove: true,
      },
      coalSubequipSetting: {
        name: "项目附属设备",
        numberperpage: 10,
        backups: true,
        batchremove: true,
      },
      coalMeapointSetting: {
        name: "项目数据项",
        numberperpage: 10,
        backups: true,
        batchremove: true,
      },
      rules: {
        numberperpage: {
          validator: checkTime,
          trigger: "blur",
        },
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    // 完成按钮
    onSubmit() {
      this.$confirm("此操作将修改设置信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var settings = [];
          settings.push(this.coalSystemSetting);
          settings.push(this.coalSubsystemSetting);
          settings.push(this.coalEquipSetting);
          settings.push(this.coalSubequipSetting);
          settings.push(this.coalMeapointSetting);
          this.putRequest("/systemSetting/updateSettingInfos", settings).then(
            (resp) => {
              // this.postRequest('/systemSetting/insertSettingInfo',this.dataFileSetting).then(resp => {
              if (resp) {
                console.log(resp);
                console.log("coalSystemSetting修改成功!");
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    initData() {
      this.getRequest(
        "/systemSetting/getSettingInfoByName/?name=" +
          this.coalSystemSetting.name
      ).then((resp) => {
        if (resp) {
          console.log(resp.data);
          this.coalSystemSetting.backups = resp.data.backups;
          this.coalSystemSetting.batchremove = resp.data.batchremove;
        }
      });
      this.getRequest(
        "/systemSetting/getSettingInfoByName/?name=" +
          this.coalSubsystemSetting.name
      ).then((resp) => {
        if (resp) {
          console.log(resp.data);
          this.coalSubsystemSetting.backups = resp.data.backups;
          this.coalSubsystemSetting.batchremove = resp.data.batchremove;
        }
      });
      this.getRequest(
        "/systemSetting/getSettingInfoByName/?name=" +
          this.coalEquipSetting.name
      ).then((resp) => {
        if (resp) {
          console.log(resp.data);
          this.coalEquipSetting.backups = resp.data.backups;
          this.coalEquipSetting.batchremove = resp.data.batchremove;
        }
      });
      this.getRequest(
        "/systemSetting/getSettingInfoByName/?name=" +
          this.coalSubequipSetting.name
      ).then((resp) => {
        if (resp) {
          console.log(resp.data);
          this.coalSubequipSetting.backups = resp.data.backups;
          this.coalSubequipSetting.batchremove = resp.data.batchremove;
        }
      });
      this.getRequest(
        "/systemSetting/getSettingInfoByName/?name=" +
          this.coalMeapointSetting.name
      ).then((resp) => {
        if (resp) {
          console.log(resp.data);
          this.coalMeapointSetting.backups = resp.data.backups;
          this.coalMeapointSetting.batchremove = resp.data.batchremove;
        }
      });
    },
  },
};
</script>

<style scoped>
.box-card {
  margin: 20px;
  padding: 40px;
  width: 1680px;
  height: 650px;
   border: 1px solid #d5ecf6;
  font-size: 15px!important;
}

.el-card:hover {
  margin-top: -3px;
}

.finish {
  /* margin-top: 10px; */
  margin-left: 1210px;
}
.head {
  float: left;
}
.box-card .text{
  display: inline-block;
  margin-left: 10px;

}
.el-form{
  margin-top: 30px;
}

.el-form-item--small.el-form-item {
    margin-bottom: 28px;
}
</style>
